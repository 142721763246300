import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { StaticImage } from 'gatsby-plugin-image'
import { Parallax } from 'react-scroll-parallax'

import { UnitProps } from 'components/app/Unit'
import { Block, Carousel, Text, Title } from 'components/shared'
import { Ribbon } from 'components/helpers'
import { Player, Unit } from 'components/app'

interface ViewProps {
  units?: UnitProps[]
}

const FranchiseView: React.FC<ViewProps> = ({ units = [] }) => (
  <>
    <Block tag="section" paddingTop="size88" paddingBottom="size88">
      <Container>
        <Row align="center" justify="center">
          <Col xs={11} sm={4}>
            <Block
              tag="header"
              position="relative"
              zIndex={2}
              marginBottom={{ xxxs: 'size32', sm: 'size0' }}
            >
              <Title
                level="h1"
                appearance="store"
                color="yellow400"
                secondColor="brown800"
                marginBottom="size12"
                lineHeight={1}
                uppercase
              >
                <span>Gostosa de</span> Lascar!
              </Title>
              <Block transform={{ sm: 'translateX(30%);' }}>
                <Title lineHeight={1} size="h3" uppercase>
                  Quem experimenta se lasca de tanta gostosura
                </Title>
              </Block>
            </Block>
          </Col>
          <Col xs={11} sm={8}>
            <StaticImage
              src="../../images/hero-about.png"
              alt="Pizza de camarão"
              layout="fullWidth"
              placeholder="blurred"
            />
          </Col>
        </Row>
      </Container>
    </Block>

    <Block>
      <Player
        youtubeId="0kU_pGFfmDI"
        poster={
          <StaticImage
            src="../../images/video-cover.png"
            alt="Pizza de doce de leite com banana"
            layout="fullWidth"
            placeholder="blurred"
          />
        }
      />
    </Block>

    <Block
      tag="section"
      position="relative"
      zIndex={20}
      paddingTop={{ xxxs: 'size48', sm: 'size88' }}
      paddingBottom={{ xxxs: 'size48', sm: 'size64' }}
    >
      <Block
        display={{ xxxs: 'none', md: 'block' }}
        position="absolute"
        top="size0"
        left={-64}
      >
        <Parallax y={[-50, 25]}>
          <Block position="relative" width={200}>
            <StaticImage
              src="../../images/parallax-leaf.png"
              alt="Follha de Manjericão"
              layout="fullWidth"
              placeholder="blurred"
            />
          </Block>
        </Parallax>
        <Parallax y={[25, -100]}>
          <Block position="relative" width={300} left={-50}>
            <StaticImage
              src="../../images/parallax-pizza-calabresa.png"
              alt="Pizza de Calabresa"
              layout="fullWidth"
              placeholder="blurred"
            />
          </Block>
        </Parallax>
      </Block>
      <Block
        display={{ xxxs: 'none', sm: 'block' }}
        position="absolute"
        top="size0"
        right={-64}
      >
        <Parallax y={[-50, 25]}>
          <Block position="relative" width="30vw">
            <StaticImage
              src="../../images/parallax-pizza-camarao.png"
              alt="Follha de Manjericão"
              layout="fullWidth"
              placeholder="blurred"
            />
          </Block>
        </Parallax>
        <Parallax y={[25, -100]} x={[50, -50]}>
          <Block position="relative" width={300} left={-50}>
            <StaticImage
              src="../../images/parallax-pizza-banana.png"
              alt="Pizza de Calabresa"
              layout="fullWidth"
              placeholder="blurred"
            />
          </Block>
        </Parallax>
      </Block>
      <Container>
        <Row justify="space-between">
          <Col xs={12} sm={8} md={7}>
            <Block tag="header" marginBottom="size32">
              <Title size="h3" lineHeight={1} uppercase>
                Sobre nós
              </Title>
            </Block>
            <Block tag="article" marginBottom="size48">
              <Text marginBottom="size12">
                A Lasca de Pizza surgiu da vontade de dois irmãos amantes pela
                culinária, no qual tiveram a idéia de montar uma pizzaria que
                trouxesse a verdadeira pizza, com foco numa massa e molho
                característico, aliado a sabor inigualável.
              </Text>
              <Text marginBottom="size12">
                Então, em 2015, eles foram para São Paulo, cidade famosa por ter
                as melhores pizzas, e lá estudaram e aprenderam os verdadeiros
                segredos das melhores pizzas.
              </Text>
              <Text>
                Ao retornar para João Pessoa/PB começaram a se dedicar numa
                receita própria que trouxesse o verdadeiro sabor da pizza e ao
                mesmo tempo o sabor característico da nossa região.
              </Text>
              <Text>
                Só em abril de 2017, que de fato surgiu a Lasca de Pizza e à
                medida que as pessoas experimentavam, foram se tornando fãs da
                Lasca.
              </Text>
              <Text>
                Mas existia outro ponto que incomodava, no mercado, todos faziam
                as pizzas de forma circular... então porque também não inovar e
                resgatar a raízes das pizzas, onde as mesmas eram em formatos
                retangulares?!
              </Text>
              <Text>
                Foi em meados de 2019, fazendo justiça ao nome da pizzaria, que
                foi lançada as pizzas retangulares nas quais eram servidas na
                forma de lascas e a partir daí passamos a ser referência em
                sabor e apresentação inigualável.
              </Text>
              <Text marginBottom="size12">
                A Lasca de Pizza é a queridinha de João Pessoa, possui duas
                Unidades, no Jardim Oceania e Bairro dos Estados, possui sabores
                diferenciados nas especialidades tradicionais e gourmet!
              </Text>
              <Text weight={700}>
                E a gente sabe, que quem experimentou, quer LASCA de novo!
              </Text>
            </Block>
          </Col>
        </Row>
      </Container>
    </Block>

    <Block height="size64" bgColor="white" position="relative" zIndex={2}>
      <Ribbon color="yellow400" slogan="secondary" angle={-1} delay="-12s" />
    </Block>

    <Block height="size64" bgColor="white" position="relative" zIndex={2}>
      <Ribbon color="brown800" slogan="primary" angle={0.4} delay="-12s" />
    </Block>

    <Block tag="section" paddingTop="size64" paddingBottom="size88">
      <Container>
        <Block tag="header" textAlign="center" marginBottom="size48">
          <Text fontSize={14} uppercase>
            Unidades
          </Text>
          <Title size="h3" uppercase>
            Faça-nos uma visita
          </Title>
        </Block>
        <Block tag="article">
          <Carousel
            swiper={{
              initialSlide: 0,
              observer: true,
              watchOverflow: true,
              simulateTouch: false,
              autoplay: {
                delay: 4000
              },
              spaceBetween: 32,
              slidesPerView: 2,
              breakpoints: {
                0: {
                  spaceBetween: 0,
                  slidesPerView: 1
                },
                962: {
                  spaceBetween: 32,
                  slidesPerView: 2
                }
              }
            }}
          >
            {units.map((unit) => (
              <Unit key={unit.id} {...unit} />
            ))}
          </Carousel>
        </Block>
      </Container>
    </Block>
  </>
)

export default FranchiseView
